<template>
    <b-container fluid>
      <!-- User Interface controls -->
      <b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4> Active Vendor List</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Active Vendor</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
	<br>
      <b-card>
      <b-row>
        <b-col lg="2">
          <!-- <b-form-group
            label="Sort : "
            label-for="sort-by-select"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            class="mb-2"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-50"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
  
             
            </b-input-group>
          </b-form-group> -->
        </b-col>
        <b-col lg="9" class="text-right">
        <!--   <b-form-group
            v-model="sortDirection"
            description="Leave all unchecked to filter on all data"
            label-cols-sm="4"
            label-align-sm="center"
            description-align-sm="center"
            class="mb-1"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
            &nbsp; Filter On &nbsp; : &nbsp; <b-form-checkbox value="name">Name</b-form-checkbox>
              <b-form-checkbox value="total_invoice">Mobile </b-form-checkbox>
              <b-form-checkbox value="total_invoiced">  Pincode </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group> -->
          
        </b-col>
        <b-col lg="1" class="text-right">
          <a href="https://apitest.venuebook.in/admin/export_leads" download class="btn btn-success float-right btn-sm" >Export Excel</a> 
        </b-col>
        <b-col lg="3" class="my-1">
          
        <!--  <b-button v-b-modal.modal-1 class="btn btn-info float-right btn-sm">Upload Vendor Excel</b-button> -->
           



        </b-col>
  
       
 
      
    </b-row>
  <b-row>
        <b-col sm="1" md="1">
          <b-form-group
          
            label-for="per-page-select"
            
            label-align-sm="left"
            label-size="sm"
            class="mb-2"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>

          
  
        </b-col>
        <b-col lg="5" >
            
         
        </b-col>
        <b-col lg="6" >
        
          <b-row>
            <b-col lg="3" >

</b-col>
            <b-col lg="9" >
              <b-form-group
            
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-2"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
  
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
            </b-col>
          <!--   <b-col lg="2" >
              <b-button v-b-modal.modal-edit class="btn btn-info float-right btn-sm">Create New</b-button> 
            </b-col> -->
          </b-row>
          
        </b-col>
  
      </b-row>
      <b-skeleton-table v-if="load_leads"
      sticky-header=true
      small
  :rows="10"
  :columns="6"
  :table-props="{ bordered: true, striped: true }"
></b-skeleton-table>
      <!-- Main table element -->
      <b-table
      v-if="!load_leads"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        :busy.sync="isBusy"
      >
      
      
      
      <template #cell(Action)="data">
   <button class="btn btn-info btn-sm" @click="view_vendor_data(data.item.total_evnt_det)">
     View
   </button> 
            
          </template>
          
         
     
  
       
      </b-table>

      <b-col sm="2" md="2" class="my-1 float-right">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
            @change="handlePageChange"
            prev-text="Prev"
            next-text="Next"
          ></b-pagination>
        </b-col>
    </b-card>
     
    </b-container>
  </template>
  

<script>
   import { mapState } from "vuex";

  
    export default {
        mixins: [],
      data() {
        return {
       
          items:[],
          fields: [
            { key: 'Month', label: 'Ref No', sortable: true, class: 'text-left' },
            { key: 'Count', label: 'Event Date', sortable: true, sortDirection: 'desc' ,class: 'text-center' },
            
            { key: 'child_venue_name', label: 'Venue name', sortable: true, sortDirection: 'desc'  ,class: 'text-center' },
            { key: 'completed', label: 'Invoice Not Done', sortable: true, sortDirection: 'desc'  ,class: 'text-center' },
            { key: 'completed', label: 'Vb Generated Bill', sortable: true, sortDirection: 'desc'  ,class: 'text-center' },
            { key: 'invoice_generate', label: 'Bill Date', sortable: true, sortDirection: 'desc'  ,class: 'text-center' },
         
           
            
         
          ],
          favourites:false,
          vendor_disabled:false,
          totalRows: 1,
          currentPage: 1,
          perPage: 15,
          pageOptions: [10, 15 , 20, 50, { value: 100, text: "All" }],
          sortBy: '',
          sortDesc: false,
          sortDirection: 'asc',
          filter: null,
          filterOn: [],
          lead_sorce:true,
          lead_source: [],
          selecttype:'+91',
          infoModal: {
            id: 'info-modal',
            title: '',
            content: ''
          },
          load_leads:true,
          isBusy:false,
          uploadFieldName:"",
          importFile: {
      import_file: [],
    },
    vendor:
    {
      name:'',
      mob:'',
      landmob:'',
      place:'',
      location:'',
      pincode:'',
      source:'',
      
    },
        }
    },
        methods: {
        
        resetInfoModal() {
          this.infoModal.title = ''
          this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
       
        async revenue_list()
        {
            this.isBusy = true
          
        this.items = [];
        this.lead_source = [];
        this.totalRows = this.checkLoadData.length
        this.isBusy = false;
        this.load_leads = false;
            for(var i=0; i < this.checkLoadData.length;i++)
            {
                this.items.push({
                  id:0,
                  Month:this.checkLoadData[i].booking_auto_id.split('-')[1],
                  Count:this.checkLoadData[i].from_date,
                  child_venue_name:this.checkLoadData[i].child_venue_name,
                  completed:this.checkLoadData[i].invoice_not_generate == 0 ? "No" :"Yes",
                  invoice_generate:this.checkLoadData[i].vb_invoice_date ? this.checkLoadData.vb_invoice_date :"-" ,
                  
                });

            }

            
        },
    

     
    
      },
  computed: {
        sortOptions() {
          // Create an options list from our fields
          return this.fields
            .filter(f => f.sortable)
            .map(f => {
              return { text: f.label, value: f.key }
            })
        },
        ...mapState(["checkLoadData"]),
      },
      mounted()
      {
        this.revenue_list();
      }
     
    }
  </script>

<style>

</style>